import { ConfirmModal } from '@jurnee/common/src/components/ConfirmModal';
import { MenuItemWrapper } from '@jurnee/common/src/components/MenuItemWrapper';
import { DotButton } from 'components/DotButton';
import { useTranslation } from 'react-i18next';

interface Props {
  onDelete(): void;
}

export function DotMenu(props: Props) {
  const { t } = useTranslation(['invoice', 'common']);

  return (
    <DotButton>
      <ConfirmModal
        buttonLabel={t('common:buttons.delete')}
        message={t('dotMenu.delete.modal.message')}
        title={t('dotMenu.delete.modal.title')}
        onConfirm={props.onDelete}
      >
        <MenuItemWrapper icon="trash" label={t('dotMenu.delete.label')} />
      </ConfirmModal>
    </DotButton>
  );
}