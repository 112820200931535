import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  FormControl,
  FormLabel,
  HStack,
  Text,
  Textarea,
  VStack,
  useDisclosure,
  useToast
} from '@chakra-ui/react';
import { PrimaryButton } from '@jurnee/common/src/components/buttons/PrimaryButton';
import { SecondaryButton } from '@jurnee/common/src/components/buttons/SecondaryButton';
import { PartnerRequestJSON } from '@jurnee/common/src/entities/PartnerRequest';
import { getErrorToast, getSuccessToast } from '@jurnee/common/src/utils/toasts';
import { ReactElement, cloneElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store';
import { getPartnerSelector } from 'store/partner/partner.selectors';
import { declinePartnerRequestThunk } from 'store/partnerRequests/partnerRequests.thunks';

interface Props {
  partnerRequestId: PartnerRequestJSON['id'];
  children: ReactElement;
  onDecline(partnerRequest: PartnerRequestJSON): void;
}

export function DeclineRequestModal(props: Props) {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const { t } = useTranslation(['request', 'common']);

  const partner = useSelector(getPartnerSelector);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [declinedReason, setDeclineReason] = useState<string>(null);
  const [isSaving, setIsSaving] = useState(false);

  async function onDecline() {
    setIsSaving(true);

    try {
      const partnerRequest = await dispatch(declinePartnerRequestThunk({
        partnerId: partner.id,
        partnerRequestId: props.partnerRequestId,
        data: { declinedReason }
      })).unwrap();

      setIsSaving(false);
      props.onDecline(partnerRequest);
      toast(getSuccessToast(t('toasts.declinePartnerRequest.success')));
      onClose();
    } catch(err) {
      setIsSaving(false);
      toast(getErrorToast(t('toasts.declinePartnerRequest.error')));
    }
  }

  return (
    <>
      { cloneElement(props.children, { onClick: onOpen }) }

      <AlertDialog leastDestructiveRef={null} isOpen={isOpen} onClose={onClose}>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>
            { t('modals.decline.title') }
            <AlertDialogCloseButton />
          </AlertDialogHeader>

          <AlertDialogBody>
            <VStack spacing={3}>
              <Text>{t('modals.decline.message')}</Text>

              <FormControl>
                <FormLabel>{t('modals.decline.reason.label')}</FormLabel>

                <Textarea
                  size="sm"
                  h="80px"
                  resize="none"
                  value={declinedReason || ''}
                  onChange={(e) => setDeclineReason(e.target.value)}
                />
              </FormControl>
            </VStack>
          </AlertDialogBody>

          <AlertDialogFooter>
            <HStack spacing={4} justifyContent="space-between" w="100%">
              <SecondaryButton colorScheme="pink" size="sm" onClick={onClose}>
                { t('common:buttons.close') }
              </SecondaryButton>

              <PrimaryButton colorScheme="pink" size="sm" onClick={onDecline} isLoading={isSaving}>
                { t('common:buttons.decline') }
              </PrimaryButton>
            </HStack>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}