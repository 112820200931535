import { ConfirmModal } from '@jurnee/common/src/components/ConfirmModal';
import { MenuItemWrapper } from '@jurnee/common/src/components/MenuItemWrapper';
import { PartnerDocumentJSON } from '@jurnee/common/src/entities/PartnerDocument';
import { PropositionJSON } from '@jurnee/common/src/entities/Proposition';
import { DotButton } from 'components/DotButton';
import { PartnerQuoteDrawer, QuoteDrawerData } from 'drawers/PartnerQuoteDrawer';
import { useTranslation } from 'react-i18next';

interface Props {
  bookingId: number;
  partnerRequestId: number;
  proposition: PropositionJSON;
  quote: PartnerDocumentJSON;
  onUpsert(data: QuoteDrawerData): void;
  onDelete(): void;
}

export function DotMenu(props: Props) {
  const { t } = useTranslation(['proposition', 'common']);

  return (
    <DotButton>
      <PartnerQuoteDrawer
        bookingId={props.bookingId}
        partnerRequestId={props.partnerRequestId}
        proposition={props.proposition}
        quote={props.quote}
        onUpsert={props.onUpsert}
      >
        <MenuItemWrapper icon="edit" label={t('dotMenu.edit.label')} />
      </PartnerQuoteDrawer>

      <ConfirmModal
        buttonLabel={t('common:buttons.delete')}
        message={t('dotMenu.delete.modal.message')}
        title={t('dotMenu.delete.modal.title')}
        onConfirm={props.onDelete}
      >
        <MenuItemWrapper icon="trash" label={t('dotMenu.delete.label')} />
      </ConfirmModal>
    </DotButton>
  );
}