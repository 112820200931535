import { Box, Heading, Tab, TabList, TabPanels, Tabs, VStack, useToast } from '@chakra-ui/react';
import { Loader } from '@jurnee/common/src/components/Loader';
import { PartnerRequestJSON } from '@jurnee/common/src/entities/PartnerRequest';
import { PropositionJSON } from '@jurnee/common/src/entities/Proposition';
import { getDefaultTabIndex } from '@jurnee/common/src/utils/tabs';
import { getErrorToast } from '@jurnee/common/src/utils/toasts';
import { getPartnerRequests } from 'api/partnersRequests';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { getPartnerSelector } from 'store/partner/partner.selectors';
import { router } from '../../router';
import { RequestsTable } from './RequestsTable';

export type TabKey = 'pending' | 'closed';

export interface GroupedRequest {
  partnerRequest: PartnerRequestJSON;
  propositions: PropositionJSON[];
}

export function Requests() {
  const toast = useToast();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation('requests');

  const partner = useSelector(getPartnerSelector);

  const [partnerRequests, setPartnerRequests] = useState<PartnerRequestJSON[]>([]);
  const [propositions, setPropositions] = useState<PropositionJSON[]>([]);

  const [isLoading, setIsLoading] = useState(true);

  const groupedRequests = useMemo(
    () => partnerRequests.map(partnerRequest => ({
      partnerRequest,
      propositions: propositions.filter(({ propositionsGroupId }) => propositionsGroupId === partnerRequest.propositionsGroup.id)
    })),
    [partnerRequests, propositions]
  );

  const tabs: { label: string, key: TabKey }[] = [{
    label: `${t('tabs.pending.label')}`,
    key: 'pending'
  }, {
    label: `${t('tabs.closed.label')}`,
    key: 'closed'
  }];

  async function fetchPartnerRequests() {
    setIsLoading(true);

    try {
      const params = new URLSearchParams({ status: 'pending', order: 'asc' });

      if (searchParams.get('tab') === 'closed') {
        params.set('status', 'closed');
        params.set('order', 'desc');
      }

      const { list, relationships: { propositions } } = await getPartnerRequests(partner.id, params);
      setPartnerRequests(list);
      setPropositions(propositions);
    } catch(error) {
      toast(getErrorToast(t('toasts.fetchPartnerRequests.error')));
    }

    setIsLoading(false);
  }

  useEffect(() => {
    fetchPartnerRequests();
  }, [searchParams]);

  function onDecline(declinedRequest: PartnerRequestJSON) {
    fetchPartnerRequests();
    setPartnerRequests(partnerRequests.map(partnerRequest => partnerRequest.id === declinedRequest.id ? declinedRequest : partnerRequest));
  }

  return (
    <Tabs
      defaultIndex={getDefaultTabIndex(tabs, router.state.location.search)}
      onChange={index => setSearchParams({ tab: tabs[index].key })}
      isLazy
    >
      <VStack w="100%" h="100%" spacing={0} minW={860}>
        <Box w="100%" bg="white">
          <Heading w="100%" lineHeight="32px" p={8}>{t('heading')}</Heading>

          <TabList>
            { tabs.map(tab => (<Tab key={tab.key}>{tab.label}</Tab>)) }
          </TabList>
        </Box>

        {
          isLoading ? (
            <Loader h={400} />
          ) : (
            <TabPanels w="100%">
              {
                tabs.map(({ key }) =>
                  <RequestsTable
                    key={key}
                    tab={key}
                    requests={groupedRequests}
                    onDecline={onDecline}
                  />
                )
              }
            </TabPanels>
          )
        }
      </VStack>
    </Tabs>
  );
}