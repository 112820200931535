import { HStack, Heading, VStack, useToast } from '@chakra-ui/react';
import { Loader } from '@jurnee/common/src/components/Loader';
import { BackButton } from '@jurnee/common/src/components/buttons/BackButton';
import { SecondaryButton } from '@jurnee/common/src/components/buttons/SecondaryButton';
import { PartnerRequestJSON } from '@jurnee/common/src/entities/PartnerRequest';
import { isPropositionsGroupDraft } from '@jurnee/common/src/utils/propositionsGroups';
import { getErrorToast } from '@jurnee/common/src/utils/toasts';
import { getPartnerRequest } from 'api/partnersRequests';
import { DeclineRequestModal } from 'modals/DeclineRequestModal';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, Params, useParams } from 'react-router-dom';
import { getPartnerSelector } from 'store/partner/partner.selectors';
import { Comments } from './Comments';
import { Details } from './Details';
import { Propositions } from './Propositions';

export function Request() {
  const toast = useToast();
  const params = useParams<Params<'partnerRequestId'>>();
  const partnerRequestId = Number(params.partnerRequestId);
  const { t } = useTranslation(['request', 'common']);

  const partner = useSelector(getPartnerSelector);
  const [partnerRequest, setPartnerRequest] = useState<PartnerRequestJSON>(null);
  const [isPartnerRequestLoading, setIsPartnerRequestLoading] = useState(true);

  async function fetchPartnerRequest() {
    try {
      const partnerRequest = await getPartnerRequest({ partnerId: partner.id, partnerRequestId });
      setPartnerRequest(partnerRequest);
      setIsPartnerRequestLoading(false);
    } catch(err) {
      toast(getErrorToast(t('toasts.fetchPartnerRequest.error')));
    }
  }

  useEffect(() => {
    fetchPartnerRequest();
  }, [partnerRequestId]);

  async function onDecline(partnerRequest: PartnerRequestJSON) {
    setPartnerRequest(partnerRequest);
  }

  if (isPartnerRequestLoading) {
    return <Loader />;
  }

  const isEditable = isPropositionsGroupDraft(partnerRequest.propositionsGroup) && !partnerRequest.declinedAt;

  return (
    <VStack w="100%" h="100%" spacing={0}>
      <HStack w="100%" p={8} bg="white" borderBottom="1px solid" borderColor="gray.200" justifyContent="space-between">
        <HStack spacing={4}>
          <Link to="/requests">
            <BackButton />
          </Link>

          <Heading lineHeight="32px">{t('heading')} #{partnerRequest.id}</Heading>
        </HStack>

        {
          isEditable &&
            <DeclineRequestModal
              partnerRequestId={partnerRequest.id}
              onDecline={onDecline}
            >
              <SecondaryButton size="sm" colorScheme="pink">
                { t('common:buttons.decline') }
              </SecondaryButton>
            </DeclineRequestModal>
        }
      </HStack>

      <HStack w="100%" maxW="1184px" alignSelf="center" p={8} spacing={8} alignItems="flex-start">
        <Details w="45%" minW={340} request={partnerRequest.propositionsGroup} />

        <VStack w="55%" minW={400} spacing={6}>
          <Propositions
            partnerRequest={partnerRequest}
            isEditable={isEditable}
          />

          <Comments partnerRequestId={partnerRequest.id} />
        </VStack>
      </HStack>
    </VStack>
  );
}