import { Box } from '@chakra-ui/react';
import { DotMenu as Menu } from '@jurnee/common/src/components/DotMenu';
import { MenuItemWrapper } from '@jurnee/common/src/components/MenuItemWrapper';
import { PartnerRequestJSON } from '@jurnee/common/src/entities/PartnerRequest';
import { isPropositionsGroupDraft } from '@jurnee/common/src/utils/propositionsGroups';
import { DeclineRequestModal } from 'modals/DeclineRequestModal';
import { useTranslation } from 'react-i18next';

interface Props {
  partnerRequest: PartnerRequestJSON;
  onDecline(partnerRequest: PartnerRequestJSON): void;
}

export function DotMenu({ partnerRequest, onDecline }: Props) {
  const { t } = useTranslation('common');

  const isEditable = isPropositionsGroupDraft(partnerRequest.propositionsGroup) && !partnerRequest.declinedAt;

  return (
    <Box onClick={e => e.stopPropagation()}>
      <Menu size="sm" color="black" placement="bottom-end">
        <DeclineRequestModal
          partnerRequestId={partnerRequest.id}
          onDecline={onDecline}
        >
          <MenuItemWrapper icon="squareRoundedX" label={t('buttons.decline')} isDisabled={!isEditable} />
        </DeclineRequestModal>
      </Menu>
    </Box>
  );
}